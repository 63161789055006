import * as React from 'react';
import colors, { shadows } from '../../styles/colors';
import { innerSpacingXL, mediaBreakpoints, spacing, spacing4XL } from '../../styles/variables';
import SingleBGArea from '../single-bg-area';
import styled from '@emotion/styled';


const Container = styled('div')`
	display: flex;
	flex-direction: column;
	@media (min-width: ${mediaBreakpoints.bp992}) {
		flex-direction: row;
		}
`

const CardParts = {
	Container: styled('div')`
		padding: ${innerSpacingXL};
		background-color: ${colors.bgWhite};
		/* height: 192px; */
		min-width: 288px;
		max-width: 336px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		${shadows.uniform}
		margin-bottom: ${spacing};
		@media (min-width: ${mediaBreakpoints.bp992}) {
			margin-left: ${spacing};
			margin-bottom: 0;
			:first-of-type{
				margin-left: 0;
			}
		}
	`,
	Text: styled('p')`
		&::before{
			content: '“';
		}
		&::after{
			content: '”';
		}
		color: ${colors.textPrimary};
		margin-bottom: ${innerSpacingXL};
		`,
	Signature: styled('div')`
		`,
	Name: styled('div')`
		color: ${colors.textPrimary};
		`,
	Job: styled('div')`
		color: ${colors.textSecondary};
	`,
}

const Wrapper = styled(SingleBGArea)`
	@media (min-width: ${mediaBreakpoints.bp992}) {
		padding: ${spacing4XL} 0;
	}	
`;

const TestimonialCard = (props) => {
	return (
		<CardParts.Container>
			<CardParts.Text>
				{props.text}
			</CardParts.Text>
			<CardParts.Signature>
				<CardParts.Name>
					{props.name}
				</CardParts.Name>
				<CardParts.Job>
					{props.job}
				</CardParts.Job>
			</CardParts.Signature>
		</CardParts.Container>
	);
}





const TestimonialsSection = () => {
	return (
		<Wrapper bgColor={colors.bgWhite}>
			<Container>
				<TestimonialCard
					text={"I love this Power Up and use it several times per week!"}
					name={"Brian"}
					job={"private user"}
				/>
				<TestimonialCard
					text={"Love this power up!!"}
					name={"Annaleigh"}
					job={"founder"}
				/>
				<TestimonialCard
					text={"I can't live without Bulk Actions!"}
					name={"Art"}
					job={"full-stack web developer"}
				/>
			</Container>
		</Wrapper>
	);
};

export default TestimonialsSection;
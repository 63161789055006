import styled from '@emotion/styled';
import colors, { shadows } from '../../styles/colors';
import SingleBGArea from '../single-bg-area';
import { innerSpacingL, spacingL } from '../../styles/variables';
import { textStyles } from '../../styles/texts';

// const Container = styled(SingleBGArea)`
// 	color: ${colors.textWhite};

// `;
const Title = styled('h2')`
	color: ${colors.textPrimary};
	margin-bottom: ${spacingL};

`;

const FactContainer = styled('div')`
	display: flex;
	flex-direction: column;
	/* width: 300px; */
	margin-bottom: ${spacingL};
	color: ${colors.textSecondary};
	/* background-color: ${colors.bgWhite};
	padding: ${innerSpacingL};
	${shadows.down} */
	`;

const FactHeading = styled('h3')`
	/* display: flex;
	flex-direction: row; */
	color: ${colors.textPrimary};
	margin-bottom: 0;
	${textStyles.xxxxlRegular}
	font-weight: 400;
	`;

const FactCaption = styled('p')`
	text-align: center;
	`;

const Fact = (props) => {
	return (
		<FactContainer>
			<FactHeading>
				{props.heading}
			</FactHeading>
			<FactCaption>{props.caption}</FactCaption>
		</FactContainer>
	)
}

const AndCounting = styled('h4')`
		text-align: center;
		color: ${colors.textPrimary};
		margin: 0;
`;

const NumberFactsSection = () => {
	return (
		<section>
			<SingleBGArea bgColor={colors.brandPrimaryLight}>
				<Title>In numbers</Title>
				<Fact
					heading={'2020'}
					caption={'first release'}
				/>
				<Fact
					heading={'50000+'}
					caption={'installations'}
				/>
				<Fact
					heading={'20000+'}
					caption={'users'}
				/>
				<AndCounting>and counting</AndCounting>
			</SingleBGArea>
		</section>
	);
};

export default NumberFactsSection;


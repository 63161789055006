import { css } from '@emotion/react';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import colors, { gradients } from '../../styles/colors';
import { mediaBreakpoints, spacing } from '../../styles/variables';
import SingleBGArea from '../single-bg-area';
import TrelloLogoWhite from '../../images/trello-logo--white.svg';
import TrelloLogoDefault from '../../images/trello-logo--default.svg';
import styled from '@emotion/styled';
import AddToTrelloButton from '../add-to-trello-button';
// import { useStaticQuery, graphql } from 'gatsby';

const Container = styled(SingleBGArea)`
	padding-bottom: 0;
	display: flex;
	flex-direction: column;
	color: ${colors.textWhite};
	/* padding: ${spacing} 0; */
	align-items: center;
	@media (min-width: ${mediaBreakpoints.bp992}) {
		& > div {
			flex-direction: row-reverse;
		}
	}
	`

const Section = styled('div')`
	max-width: 720px;
	@media (min-width: ${mediaBreakpoints.bp992}) {
		min-width: 480px;
	}
`;

const TrelloLogo = styled(TrelloLogoDefault)`
    position: relative;
    top: 1px;
		height: 30px;
		left: -3px;
`;

const Jumbotron = () => {

	// 	const data = useStaticQuery(graphql`
	// 	query {
	//   allImageSharp {
	//     edges {
	//       node {
	//         gatsbyImageData
	//         parent {
	//           ... on File {
	//             relativePath
	//           }
	//         }
	//       }
	//     }
	//   }
	// }
	// 	`)

	// 	const getImage = (file: string) => data.allImageSharp.edges.find(x => x.node.parent.relativePath == file).node.gatsbyImageData;

	return (
		<Container bgColor={gradients.green}>
			<StaticImage
				alt="Laptop with Buk Actions screen"
				src="../../images/hero-screen-laptop.png"
				quality={100}
				// width={900}
				// breakpoints={[350, 550, 750, 1080]}
				css={css`max-width: 720px;`}
			/>
			{/* <GatsbyImage image={getImage('hero-screen-laptop.png')} alt={"Laptop with Buk Actions screen"} /> */}
			<Section>
				<h1>Working with multiple <TrelloLogo />cards has never been easier</h1>
				<p>Never again loose time or get lost, while editing multiple cards in Trello. Move, relabel, modify Custom Fields and more in seconds, and always know where you are.</p>
				<AddToTrelloButton css={css`margin-top: ${spacing};`} parent='jumbotron' />
			</Section>
		</Container>
	);
};

export default Jumbotron;
import * as React from 'react';
import colors from '../../styles/colors';
import { spacing, spacingL } from '../../styles/variables';
import SingleBGArea from '../single-bg-area';
import styled from '@emotion/styled';
import AddToTrelloButton from '../add-to-trello-button';

const Container = styled(SingleBGArea)`
	padding-top: 0;
	padding-bottom: 0;
`

const Section = styled('div')`
	display: flex;
	flex-direction: column;
	color: ${colors.textWhite};
	padding: ${spacingL} ${spacing};
	align-items: center;
	background-color: ${colors.brandPrimary};
	width: 100%;
	text-align: center;
`;

const SubText = styled('p')`
	margin-bottom: ${spacing};
`;



const FinalCTASection = () => {
	return (
		<Container bgColor={colors.bgWhite}>
			<Section>
				<h2>Join 20000+ users all around the world</h2>
				<SubText>Save time and maintain overview when working with multiple cards.</SubText>
				<AddToTrelloButton parent='final-cta-section' />
			</Section>
		</Container>
	);
};

export default FinalCTASection;
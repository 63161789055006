import { css } from '@emotion/react';
import styled from '@emotion/styled';
import * as React from 'react';
import colors from '../../styles/colors';
import { mediaBreakpoints, spacingL, spacing4XL } from '../../styles/variables';
import SingleBGArea from '../single-bg-area';
import ActionsCloud from '../../images/feature__actions-cloud.svg';
import { StaticImage } from 'gatsby-plugin-image';
// import { graphql, useStaticQuery } from 'gatsby';



const FeatureSection = styled('div')`
	display: flex;
	flex-direction: column;
	color: ${colors.textWhite};
	align-items: center;
	max-width: 528px;
	@media (min-width: ${mediaBreakpoints.bp992}) {
		max-width: none;
		flex-direction: row;
		width: 100%;
    justify-content: space-between;
	}
`;

const FeatureDescription = styled('div')`
	margin-top: ${spacingL};
	width: 300px;
	`;

const FeatureTitle = styled('h3')`
	margin-top: 0;
	color: ${colors.textPrimary};
`;

const FeatureImage = styled('div')`
		max-width: 504px;
`;

const FeatureText = styled('p')`
	color: ${colors.textSecondary};
	b {
		color: ${colors.textPrimary};
		/* font-weight: 600; */
	}
`;

const Wrapper = styled('section')`
	@media (min-width: ${mediaBreakpoints.bp992}) {
		& > div:nth-of-type(2n) > div > div {
			flex-direction: row-reverse;
		}
	}	
`;

const Container = styled(SingleBGArea)`
	@media (min-width: ${mediaBreakpoints.bp992}) {
		padding: ${spacing4XL} 0;
	}	
`;

const FeaturesSection = () => {

	// 	const data = useStaticQuery(graphql`
	// 	query {
	//   allImageSharp {
	//     edges {
	//       node {
	//         gatsbyImageData(quality: 100, layout: CONSTRAINED)
	//         parent {
	//           ... on File {
	//             relativePath
	//           }
	//         }
	//       }
	//     }
	//   }
	// }
	// 	`)

	// 	const getImage = (file: string) => data.allImageSharp.edges.find(x => x.node.parent.relativePath == file).node.gatsbyImageData;

	// console.log(getImage('feature__overview-section.png'))


	return (
		<Wrapper>
			<Container bgColor={colors.brandPrimaryLight}>
				<FeatureSection>
					<FeatureImage>
						<ActionsCloud css={css`width: 100%; height: auto;`} />
					</FeatureImage>
					<FeatureDescription>
						<FeatureTitle>Save time</FeatureTitle>
						<FeatureText>No more updating cards one at a time. Select all the cards that need modifying and <b>perform the chosen action only once</b>.
							{/* <a href='/'>Read more</a> */}
						</FeatureText>
					</FeatureDescription>
				</FeatureSection>
			</Container>
			<Container bgColor={colors.bgWhite}>
				<FeatureSection>
					<FeatureImage>

						<StaticImage
							alt="Features Overview Section"
							src="../../images/feature__overview-section.png"
							quality={100}
							width={500}
						// breakpoints={[300, 400, 500]}
						/>
						{/* <GatsbyImage image={getImage('feature__overview-section.png')} alt={"Feature: Overview Section"} /> */}
					</FeatureImage>
					<FeatureDescription>
						<FeatureTitle>Gain certainty</FeatureTitle>
						<FeatureText>Handy <b>overview section</b> lets you know how exactly the changes will look like and which cards will be affected.</FeatureText>
					</FeatureDescription>
				</FeatureSection>
			</Container>
			<Container bgColor={colors.brandPrimaryLight}>
				<FeatureSection>
					{/* <Chaining css={css`width: 100%; height: auto;`} /> */}
					<FeatureImage>

						<StaticImage
							alt="Features Overview Section"
							src="../../images/feature__chaining.png"
							quality={100}
							width={500}
						// breakpoints={[300, 400, 500]}
						/>
						{/* <GatsbyImage image={getImage('feature__chaining.png')} alt={"Feature: Chaining"} /> */}
					</FeatureImage>
					<FeatureDescription>
						<FeatureTitle>Perform multiple actions with ease</FeatureTitle>
						<FeatureText><b>Chaining actions</b> allows you to keep cards selected after each one, so you don’t have to re-select them every time.</FeatureText>
					</FeatureDescription>
				</FeatureSection>
			</Container>
			<Container bgColor={colors.bgWhite}>
				<FeatureSection>
					<FeatureImage>
						<StaticImage
							alt="Feature: Custom Views"
							src="../../images/feature__custom-views.png"
							quality={100}
							width={500}
						// layout="constrained"
						// breakpoints={[400, 550, 750, 990]}
						// breakpoints={[350, 450, 500]}
						/>
						{/* <GatsbyImage image={getImage('feature__custom-views.png')} alt={"Feature: Custom Views"} /> */}
					</FeatureImage>
					<FeatureDescription>
						<FeatureTitle>Quickly find and select the cards you need</FeatureTitle>
						<FeatureText><b>Custom views</b> allow you to find the cards that need changing in seconds, and save the most useful filter parameters for quick access.</FeatureText>
					</FeatureDescription>
				</FeatureSection>
			</Container>
		</Wrapper>
	);
};

export default FeaturesSection;
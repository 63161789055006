import * as React from 'react'
import Layout from '../components/layout'
import FeaturesSection from '../components/index/features-section'
import PopularActionsSections from '../components/index/popular-actions-section'
import Jumbotron from '../components/index/jumbotron'
import NumberFactsSection from '../components/index/number-facts-section'
import TestimonialsSection from '../components/index/testimonials-section'
import FinalCTASection from '../components/index/final-cta-section'
import { css, Global } from '@emotion/react'
import { textStyles } from '../styles/texts'
import colors from '../styles/colors'
import { trackEvent } from '../tracking'
import SEO from '../components/seo'



const IndexPage = () => {

	React.useEffect(() => {
		trackEvent('HomeLoaded')
	}, [])

	return (
		<Layout>
			<SEO />
			<Global
				styles={indexStyles}
			/>
			<Jumbotron />
			<FeaturesSection />
			<PopularActionsSections />
			<NumberFactsSection />
			<TestimonialsSection />
			<FinalCTASection />
		</Layout>
	)
}

export default IndexPage

const indexStyles = css`
h1 {
	${textStyles.xxxxlRegular}
}

h2 {
${textStyles.xxxlMedium}
}

h3 {
${textStyles.xxlMedium}
}

h4 {
${textStyles.xlMedium}
}

a {
color: ${colors.textLink};
}
`
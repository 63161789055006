import { css } from '@emotion/react';
import styled from '@emotion/styled';
import * as React from 'react';
import colors, { gradients, shadows } from '../../styles/colors';
import SingleBGArea from '../single-bg-area';
import { Icon } from '@iconify/react';
import { innerSpacing, innerSpacingL, mediaBreakpoints, spacing, spacingL } from '../../styles/variables';

const Container = styled(SingleBGArea)`
	color: ${colors.textWhite};
	
`;

const Title = styled('h2')`
	color: ${colors.textWhite};
	margin-bottom: ${spacingL};
	`;

const ActionCardContainer = styled('div')`
	display: flex;
	flex-direction: column;
	width: 300px;
	/* margin-right: ${spacing}; */
	color: ${colors.textSecondary};
	background-color: ${colors.bgWhite};
	padding: ${innerSpacingL};
	${shadows.down}

	`;

const ActionCardTitle = styled('h3')`
	display: flex;
	flex-direction: row;
	color: ${colors.textPrimary};
	margin-bottom: ${innerSpacing};
	justify-content: center;
	`;

const ActionCardTitleIcon = styled('span')`
	display: flex;
	height: 36px;
	width: 36px;
	justify-content: center;
	align-items: center;
	background: ${colors.brandPrimaryLight};
	border-radius: 18px;
	margin-right: ${innerSpacingL};
	`;

const ActionCardTitleText = styled('span')`
`;

const ActionCardText = styled('p')`
	text-align: center;
	`;

const ActionCard = (props) => {
	return (
		<ActionCardContainer>
			<ActionCardTitle>
				<ActionCardTitleIcon><Icon icon={props.icon} height="22" /></ActionCardTitleIcon>
				<ActionCardTitleText>{props.title}</ActionCardTitleText>
			</ActionCardTitle>
			<ActionCardText>{props.text}</ActionCardText>
		</ActionCardContainer>
	)
}

const CardsContainer = styled('div')`
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
    justify-content: center;
		gap: ${spacing};
		margin-bottom: ${spacingL};

		/* @media (min-width: ${mediaBreakpoints.bp992}) {
			flex-direction: row;
			margin-left: ${spacing};
			margin-bottom: 0;
			:first-child{
				margin-left: 0;
			}
		} */
`;

const AndMore = styled('h4')`
		text-align: center;
		color: ${colors.textPrimary};
		margin: 0;
`;

const PopularActionsSections = () => {
	return (
		<section>
			<Container bgColor={gradients.green}>
				<Title>Popular Actions</Title>
				<CardsContainer>

					<ActionCard
						icon={'gridicons:custom-post-type'}
						title={'Custom Fields'}
						text={'List, date, number, and other kinds of fields can be freely added, removed and modified.'}
					/>
					<ActionCard
						icon={'fluent:copy-arrow-right-24-filled'}
						title={'Move cards'}
						text={'Move any set of cards to a specified list on the current or different board'}
					/>
					<ActionCard
						icon={'zmdi:labels'}
						title={'Update Labels'}
						text={'Quickly assign or remove a number of labels to multiple cards in one go.'}
					/>
					<ActionCard
						icon={'bi:calendar-date-fill'}
						title={'Due dates'}
						text={'Assign a fixed date to multiple cards or shift date by a specific number of days/weeks etc.'}
					/>
					<ActionCard
						icon={'ep:delete-filled'}
						title={'Delete cards'}
						text={'Avoid archiving and only then deleting tens of cards. Do it all in one step.'}
					/>
					<ActionCard
						icon={'fluent:copy-add-24-filled'}
						title={'Copy cards'}
						text={'Copy multiple cards to a specified list on this or a different board.'}
					/>
				</CardsContainer>
				<AndMore>and more...</AndMore>
			</Container>
		</section>
	);
};

export default PopularActionsSections;

